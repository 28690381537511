import { axiosClient, axiosPublicClient } from './request';

const userServices = {
  createUser(body) {
    return axiosClient.post('/users', body);
  },
  getUserDetail(id) {
    return axiosClient.get(`/users/${id}`);
  },
  updateUser(id, body) {
    return axiosClient.put(`/users/${id}`, body);
  },
  updateUserSetting(body) {
    return axiosClient.put(`/users/me/settings`, body);
  },
  userProfile() {
    return axiosClient.get('/users/me');
  },
  updateUserProfile(body) {
    return axiosClient.put('/users/me', { ...body, platform: 'web' });
  },
  updateViewAsRole(roleId) {
    return axiosClient.put(`/users/me/roles/${roleId}`);
  },
  userProfileByToken(accessToken) {
    return axiosPublicClient.get('/users/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },
  listUser({ pageSize = 20, pageIndex = 0, sort_by = [], globalFilter = '' } = {}) {
    return axiosClient.get('/users', {
      params: {
        sort_by,
        limit: pageSize,
        page: pageIndex + 1,
        global_search_field: globalFilter
      }
    });
  },
  getOnBoardingUser(searchValue) {
    return axiosClient.get('/onboardings', { params: { search_value: searchValue } });
  },
  checkValidInfo(params) {
    return axiosClient.get('/users/checking', { params });
  },
  resendValidationEmail(userId) {
    return axiosClient.post(`/users/${userId}/resending`);
  }
};

export default userServices;
